<template>
	<div class="streaming el-content">
		<div class="tips">
			<p>提示</p>
			<p>
				1、添加直播间请前往<a href="https://mp.weixin.qq.com/" target="_blank">微信公众平台</a>进行添加直播间！
				<a-tooltip content >
					<template #title>
						<div>
							<img  src="@/assets/img/streaming.png" alt="" >
						</div>
					</template>
					<i class="el-icon-info"></i>
				</a-tooltip>
			</p>
			<p>2、直播功能暂时只支持微信小程序直播！</p>
		</div>
		<a-table :pagination="false" row-key="roomid" :data-source="info.list" :columns="[
			{title:'roomid',dataIndex:'roomid'},
			{title:'直播间名称',dataIndex:'name'},
			{title:'主播名称',dataIndex:'anchor_name'},
			{title:'直播时间',dataIndex:'startTime',slots:{customRender:'startTime'}},
		]">
			<template #startTime="{record}">
				<span>{{record.start_time}}~{{record.end_time}}</span>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getStreaming(info.page,e)}"
				@change="(e)=>{getStreaming(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import common from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
		})
		getStreaming(1,state.info.limit)
		function getStreaming(page,limit){
			page=page-1
			common.getStreaming(page,limit,res=>state.info = {limit,...res})
		}

		return{
			...toRefs(state),
			getStreaming
		}
	}
}
</script>

<style lang="scss">
	.streaming-goods-info{
		display: flex;
		
		.sgi-name{
			width: 60%;
		}
		.sgi-cover{
			width: 70px;
			height: 70px;
		}
	}
</style>
